import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './oneProject.module.scss';
import Navbar from 'Components/Navbar';
import Button from 'Components/Button';
import ProjectCard from 'Components/ProjectCard';
import OtherFooter from 'Components/OtherFooter';
import dots from 'Assets/images/dots.png';
import pro1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import loc from 'Assets/images/projectcard/Vector.png';
import price from 'Assets/images/suitcase.png';
import date from 'Assets/images/date.png';
import authorPic from "Assets/images/Ellipse 17.png";

export default function OneProject() {
  const history = useHistory();

  return (
    <main className={styles.oneproject}>
      <Navbar />
      <section className={styles.top}>
        <h2 className={styles.title}>
          Build a backend system for an online bootcamp web app
        </h2>
        <div className={styles.details}>
          <div className={styles.left}>
            <img src={pro1} alt="Project image" />
          </div>
          <div className={styles.right}>
            <p className={styles.location}>
              <img src={loc} alt="location" />
              Sweden, Stockholm
            </p>
            <p className={styles.body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
              ex ea commodo consequat.
            </p>
            <div className={styles.under}>
              <p className={styles.price}>
                <img src={price} alt="price" />
                $11,500
              </p>
              <p className={styles.datedue}>
                <img src={date} alt="due date" />
                Due by March 2
              </p>
            </div>
            <div className={styles.tag}>
              <p className={styles.tags}>Python</p>
              <p className={styles.tags}>NodeJs</p>
              <p className={styles.tags}>PHP</p>
              <p className={styles.tags}>ReactJs</p>
              <p className={styles.tags}>Canva</p>
              <p className={styles.tags}>Excel</p>
              <p className={styles.tags}>UI/UX</p>
              <p className={styles.tags}>Data Science</p>
            </div>
            <div className={styles.action}>
              <Button
                text="Apply as a Freelancer"
                small
                action={() => history.push("/auth/signin")}
                styles= {{
                  padding: "15px 40px"
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.middle}>
        <h3>More projects</h3>
        <div className={styles.otherprojects}>
          <ProjectCard
            action={() => history.push("/projects/1")}
            image={pro1}
            title="Backend API Project For online Bootcamp Workspace"
            price="$25,000"
            author={authorPic}
            name="Jody Booms"
            location="USA"
          />
          <ProjectCard
            action={() => history.push("/projects/1")}
            image={pro1}
            title="Backend API Project For online Bootcamp Workspace"
            price="$25,000"
            author={authorPic}
            name="Jody Booms"
            location="USA"
          />
          <ProjectCard
            action={() => history.push("/projects/1")}
            image={pro1}
            title="Backend API Project For online Bootcamp Workspace"
            price="$25,000"
            author={authorPic}
            name="Jody Booms"
            location="USA"
          />
        </div>
        <img className={styles.dots} src={dots} alt="pagination dots" />
      </section>
      <OtherFooter />
    </main>
  );
}