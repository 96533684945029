import React, { FunctionComponent } from "react";
import { ICourseCard } from "../../models";

import Styles from "./index.module.scss";

const CourseCard: FunctionComponent<ICourseCard> = ({
  title,
  description,
  date,
  img,
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.card__image}>
        <img src={img} alt="course" />
      </div>
      <div className={Styles.card__body}>
        <h3 className={Styles.card__title}>{title}</h3>
        <p className={Styles.card__desc}>{description}</p>
        <p className={Styles.card__date}>{date}</p>
      </div>
    </div>
  );
};

export default CourseCard;
