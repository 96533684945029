import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './allProject.module.scss';
import ProjectCard from 'Components/ProjectCard';
import Navbar from "Components/Navbar";
import OtherFooter from 'Components/OtherFooter';
import dots from 'Assets/images/dots.png';
import pro1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import pro4 from "Assets/images/projectcard/Rectangle 9 (2).png";
import pro2 from "Assets/images/projectcard/Rectangle 9 (1).png";
import pro3 from "Assets/images/projectcard/Rectangle 9 (3).png";
import pro5 from "Assets/images/projectcard/Rectangle 9 (5).png";
import pro6 from "Assets/images/projectcard/Rectangle 9.png";
import authorPic from "Assets/images/Ellipse 17.png";

export default function Projects() {
  const history = useHistory();

  return (
    <main className={styles.allProject}>
      <Navbar />
      <section className={styles.top}>
        <div className={styles.heading}>
          <h1>Workova Projects</h1>
          <p>Find amazing projects on workova</p>
        </div>
        <div className={styles.topProjects}>
          <ProjectCard
            action={() => history.push("/projects/1")}
            image={pro1}
            title="Backend API Project For online Bootcamp Workspace"
            price="$25,000"
            author={authorPic}
            name="Jody Booms"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            location="USA"
            straight
            styles= {{
              paddingBottom: "40px"
            }}
          />
          <ProjectCard
            action={() => history.push("/projects/1")}
            image={pro2}
            title="Backend API Project For online Bootcamp Workspace"
            price="$25,000"
            author={authorPic}
            name="Jody Booms"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            location="USA"
            straight
            styles= {{
              paddingBottom: "40px"
            }}
          />
          <ProjectCard
            action={() => history.push("/projects/1")}
            image={pro3}
            title="Backend API Project For online Bootcamp Workspace"
            price="$25,000"
            author={authorPic}
            name="Jody Booms"
            text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            location="USA"
            straight
            styles= {{
              paddingBottom: "40px"
            }}
          />
        </div>
        <img className={styles.dots} src={dots} alt="pagination dots" />
      </section>
      <section className={styles.middle}>
        <ProjectCard
          action={() => history.push("/projects/1")}
          image={pro1}
          title="Backend API Project For online Bootcamp Workspace"
          price="$25,000"
          author={authorPic}
          name="Jody Booms"
          location="USA"
        />
        <ProjectCard
          action={() => history.push("/projects/1")}
          image={pro2}
          title="Backend API Project For online Bootcamp Workspace"
          price="$25,000"
          author={authorPic}
          name="Jody Booms"
          location="USA"
        />
        <ProjectCard
          action={() => history.push("/projects/1")}
          image={pro3}
          title="Backend API Project For online Bootcamp Workspace"
          price="$25,000"
          author={authorPic}
          name="Jody Booms"
          location="USA"
        />
        <ProjectCard
          action={() => history.push("/projects/1")}
          image={pro4}
          title="Backend API Project For online Bootcamp Workspace"
          price="$25,000"
          author={authorPic}
          name="Jody Booms"
          location="USA"
        />
        <ProjectCard
          action={() => history.push("/projects/1")}
          image={pro5}
          title="Backend API Project For online Bootcamp Workspace"
          price="$25,000"
          author={authorPic}
          name="Jody Booms"
          location="USA"
        />
        <ProjectCard
          action={() => history.push("/projects/1")}
          image={pro6}
          title="Backend API Project For online Bootcamp Workspace"
          price="$25,000"
          author={authorPic}
          name="Jody Booms"
          location="USA"
        />
      </section>
      <OtherFooter />
    </main>
  );
}