export const USER_PROJECTS_REQUEST = "USER_PROJECTS_REQUEST";
export const USER_PROJECTS_SUCCESS = "USER_PROJECTS_SUCCESS";
export const USER_PROJECTS_FAILURE = "USER_PROJECTS_FAILURE";

export const NEW_PROJECTS_REQUEST = "NEW_PROJECTS_REQUEST";
export const NEW_PROJECTS_SUCCESS = "NEW_PROJECTS_SUCCESS";
export const NEW_PROJECTS_FAILURE = "NEW_PROJECTS_FAILURE";

export const GET_PROJECT_DETAILS = "GET_PROJECT_DETAILS";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "GET_PROJECT_FAILURE";
