import React, { useState } from "react";
import chat from "Assets/images/chat.svg";
import briefcase from "Assets/images/briefcase.svg";
import doublecheck from "Assets/images/doublecheck.svg";
// import closeicon from "Assets/images/close.svg";
import order from "Assets/images/order.svg";
// import search from "Assets/images/search.svg";
import chev from "Assets/images/chev-right.svg";
import chevblue from "Assets/images/chevblue.svg";
import desk from "Assets/images/desk.svg";
import plane from "Assets/images/plane.svg";
import star2 from "Assets/images/star2.svg";
import legal from "Assets/images/legal.svg";
import playBtn from "Assets/images/playBtn.svg";
import aiimg from "Assets/images/aiimg.png";
import flagBlue from "Assets/images/flag2.svg";
import flagBlue2 from "Assets/images/flag3.svg";
// import Select, { components } from "react-select";
import Navbar from "Components/Navbar";
import TalentCard from "Components/TalentCard";
import styles from "./style.module.scss";
import { Link, useHistory } from "react-router-dom";
import BackCard from "Components/BackCard";
import leftarrCircle from "Assets/images/leftarrCircleBlue.svg";
import rightarrCircle from "Assets/images/rightarrCircleBlue.svg";
import pic1 from "Assets/images/Group276.png";
import pic2 from "Assets/images/Group277.png";
import pic3 from "Assets/images/Group278.png";
import pic4 from "Assets/images/Group279.png";
import img1 from "Assets/images/Group281.png";
import back from "Assets/images/cardback.svg";
import authorPic from "Assets/images/Ellipse 17.png";
import Button from "Components/Button";
import OtherFooter from "Components/OtherFooter";

export const colourOptions = [
  { value: "Python", label: "Python" },
  { value: "NodeJs", label: "NodeJs" },
  { value: "PHP", label: "PHP" },
  { value: "ReactJs", label: "ReactJs" },
  { value: "Canva", label: "Canva" },
  {
    value: "Product Development",
    label: "Product Development",
    color: "#FFC400",
  },
  { value: "Ruby", label: "Ruby" },
  { value: "Ruby on Rails", label: "Ruby on Rails" },
  { value: "VueJs", label: "VueJs" },
  { value: "Angular", label: "Angular" },
];

export default function Home() {
  const history = useHistory();
  const [eventCard, setEventCard] = useState(0);

  return (
    <div className={styles.home}>
      <Navbar />
      <section className={styles.banner}>
        <h1>
          Search, interview and hire <br />
          verified freelancers
        </h1>
        <p>
          We connect highly skilled talents with businesses to enable them to
          get high paying jobs, share their workload and earn a steady income in
          a productive workspace
        </p>
        <div className={styles.hire}>
          <Link to="/auth/hire-talent-1">
            Hire a Talent <img src={chev} alt="chev icon" />
          </Link>
          <Link to="/auth/hire-talent-1" className={styles.link}>
            Start earning as a freelancer <img src={chev} alt="chev icon" />
            <img src={chev} alt="chev icon" />
          </Link>
        </div>
      </section>
      {/* <section className={styles.filters}>
        <p>Find Freelancers</p>
        <div className={styles.filters__container}>
          <div className={styles.left}>
            <Select
              closeMenuOnSelect={false}
              components={{
                DropdownIndicator: null,
                MultiValueRemove: (props: any) => (
                  <components.MultiValueRemove {...props}>
                    <img src={closeicon} alt="close" />
                  </components.MultiValueRemove>
                ),
              }}
              defaultValue={[colourOptions[2], colourOptions[3]]}
              isMulti
              name="colors"
              options={colourOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              isClearable={false}
              styles={{
                control: (base) => ({
                  border: "none",
                }),
                valueContainer: (base) => ({
                  ...base,
                  display: "flex",
                  flexDirection: "row",
                  // flexWrap: "nowrap",
                  // overflow: "auto",
                  // width: "500px",
                }),
                multiValue: (base) => ({
                  ...base,
                  fontSize: "18px",
                  padding: "8px 8px 8px 12px",
                  color: "#04367A",
                  backgroundColor: "#EAF3FE",
                  borderRadius: "2px",
                  margin: "6px",
                  minWidth: "100px",
                  width: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  height: "100%",
                  verticalAlign: "middle",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                  marginTop: "3px",
                  backgroundColor: "transparent !important",
                }),
              }}
            />
          </div>
          <div className={styles.right}>
            <div className={styles.orders}>
              <button>
                <img src={order} alt="" />
                Select Budget
              </button>
              <button>
                <img src={order} alt="" />
                Exclude countries
              </button>
            </div>
            <button>
              <img src={search} alt="" />
            </button>
          </div>
        </div>
      </section> */}
      <section className={styles.talents}>
        <h1>Highly skilled and verified talents</h1>
        <div className={styles.verified}>
          <TalentCard
            image={pic1}
            name="Pablo Escobar"
            badge
            title="Product Manager"
            country="Colombia"
          />
          <TalentCard
            image={pic2}
            name="Pablo Escobar"
            badge
            title="Product Manager"
            country="Colombia"
          />
          <TalentCard
            image={pic3}
            name="Pablo Escobar"
            badge
            title="Product Manager"
            country="Colombia"
          />
          <TalentCard
            image={pic4}
            name="Pablo Escobar"
            badge
            title="Product Manager"
            country="Colombia"
          />
        </div>
      </section>
      <section className={styles.projects}>
        <div className={styles.left}>
          <h1>
            Really amazing and interesting projects that solve realife problems
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum.
          </p>
          <Button
            text="View All Projects"
            action={() => history.push("projects")}
            chev
            medium
            styles={{
              fontSize: "14px",
              padding: "15px 40px",
            }}
          />
          {/* <Link to="projects">
            View All Projects <img src={chevsm} alt="chevron icon" />
          </Link> */}
        </div>
        <div className={styles.right}>
          <div className={styles.carousel}>
            <main>
              <BackCard
                backImg={back}
                date="April 4"
                title="The new musical app in the industry"
                image={authorPic}
                name="Adeosun Babra"
                country="Brazil"
              />
              <BackCard
                backImg={img1}
                date="June 6"
                title="Artificial Intelligence Hand and Fingers"
                image={authorPic}
                name="Whizney Black"
                country="USA"
              />
            </main>
          </div>
          <footer>
            <button className={styles.button}>
              <img src={order} alt="order icon" />
              Software Development
            </button>
            <span>
              <button onClick={() => null}>
                <img src={leftarrCircle} alt="left button" />
              </button>
              <button onClick={() => null}>
                <img src={rightarrCircle} alt="right button" />
              </button>
            </span>
          </footer>
        </div>
      </section>
      <section className={styles.talentHire}>
        <h1>Hire Talents Easily</h1>
        <main>
          <div className={styles.item}>
            <img src={chat} alt="talk icon" />
            <h2>
              Talk To One of Our <br /> Industry Experts
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolo
            </p>
          </div>
          <div className={styles.item}>
            <img src={briefcase} alt="talk icon" />
            <h2>
              Work With a <br />
              Verified Talent
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut
            </p>
          </div>
          <div className={styles.item}>
            <img src={doublecheck} alt="talk icon" />
            <h2>
              Your Project Done <br />
              and delivered
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolo
            </p>
          </div>
        </main>
        <Button
          text="Hire A Freelancer"
          action={() => history.push("auth/hire-talent-1")}
          chev
          medium
          red
          styles={{
            fontSize: "14px",
            padding: "15px 40px",
          }}
        />
      </section>
      <section className={styles.successVideo}>
        <div className={styles.left}>
          <div className={styles.card}>
            <div className={styles.backdrop}>
              <img src={aiimg} alt="background" />
            </div>
            <div className={styles.content}>
              <h2>Scaling Revenue With Marketing Automation and AI Engines</h2>

              <div className={styles.below}>
                <div className={styles.profile}>
                  {/* <div className={styles.avatar}></div> */}
                  <img className={styles.avatar} src={authorPic} alt="" />
                  <div className={styles.user}>
                    <h4>Whizney White</h4>
                    <div className={styles.details}>
                      <img src={flagBlue} alt="flag icon" />
                      <span>USA</span>
                      <span>June 6</span>
                    </div>
                  </div>
                </div>
                <button className={styles.btn}>
                  <img src={playBtn} alt="play icon" /> Watch The Video
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <h1>
            People create stories <br /> after successful work
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum.
          </p>
          <Button
            text="More Success Stories"
            action={() => null}
            chev
            styles={{
              fontSize: "14px",
              padding: "15px 40px",
            }}
          />
        </div>
      </section>
      <section className={styles.whyus}>
        <div className={styles.left}>
          <h1>
            Why the Workova <br /> platform is preferred
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis
            aute irure dolor in reprehenderit in voluptate velit esse cillum.
          </p>

          <div className={styles.foot}>
            <Button
              text="Hire a freelancer"
              action={() => history.push("auth/hire-talent-1")}
              red
              chev
              styles={{
                fontSize: "14px",
                padding: "15px 40px",
              }}
            />
            <Link to="auth/signup">
              Start earning as a freelancer
              <img src={chevblue} alt="chevron icon" />
              <img src={chevblue} alt="chevron icon" />
            </Link>
          </div>
        </div>

        <div className={styles.right}>
          <div className={`${styles.smCard}`}>
            <img src={desk} alt="desk icon" />
            <p>Workspaces with steady power supply</p>
          </div>
          <div className={`${styles.smCard}`}>
            <img src={star2} alt="star icon" />
            <p>Workspaces with steady power supply</p>
          </div>
          <div className={`${styles.smCard}`}>
            <img src={legal} alt="legal icon" />
            <p>Workspaces with steady power supply</p>
          </div>
          <div className={`${styles.smCard}`}>
            <img src={plane} alt="plane icon" />
            <p>Workspaces with steady power supply</p>
          </div>
        </div>
      </section>
      <section className={styles.ready}>
        <h1>Ready to start?</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt voluptate velit esse cillum.
        </p>
        <Button
          text="Hire a freelancer"
          action={() => history.push("auth/hire-talent-1")}
          red
          chev
          styles={{
            fontSize: "14px",
            padding: "15px 40px",
          }}
        />
      </section>
      <section className={styles.company}>
        <div className={styles.left}>
          <div className={`${styles.smCard}`}>
            <h1>COMPANY</h1>
            <span>
              <img src={flagBlue} alt="flag icon" />
              USA
            </span>
          </div>
          <div className={`${styles.smCard}`}>
            <h1>COMPANY</h1>
            <span>
              <img src={flagBlue2} alt="flag icon" />
              USA
            </span>
          </div>
          <div className={`${styles.smCard}`}>
            <h1>COMPANY</h1>
            <span>
              <img src={flagBlue2} alt="flag icon" />
              France
            </span>
          </div>
          <div className={`${styles.smCard}`}>
            <h1>COMPANY</h1>
            <span>
              <img src={flagBlue} alt="flag icon" />
              Nigeria
            </span>
          </div>
          <footer>
            <button onClick={() => null}>
              <img src={leftarrCircle} alt="left button" />
            </button>
            <button onClick={() => null}>
              <img src={rightarrCircle} alt="right button" />
            </button>
          </footer>
        </div>
        <div className={styles.right}>
          <h1>
            Trusted by <br />
            recruiters, businesses <br />
            and startups
          </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
      </section>
      {/* <section className={styles.courses}>
        <h1>Digest knowledge from our courses</h1>
      </section>
      <section className={styles.events}>
        <div className={styles.top}>
          <h1>Events</h1>
          <Button text="More Events" chev action={() => null} />
        </div>
        <div
          className={`${styles.cards} ${eventCard === 1 ? styles.cards2 : ""} ${
            eventCard === 2 ? styles.cards3 : ""
          }`}
        >
          <div onMouseEnter={() => setEventCard(0)} className={styles.card}>
            <div className={styles.image}></div>
            <div className={styles.content}>
              <span>Tech Event</span>
              <h2>
                The gathering of Highly skilled Developers under a roof: Boom!
              </h2>
            </div>
          </div>
          <div onMouseEnter={() => setEventCard(1)} className={styles.card}>
            <div className={styles.image}></div>
            <div className={styles.content}>
              <span>Tech Event</span>
              <h2>
                The gathering of Highly skilled Developers under a roof: Boom!
              </h2>
            </div>
          </div>
          <div onMouseEnter={() => setEventCard(2)} className={styles.card}>
            <div className={styles.image}></div>
            <div className={styles.content}>
              <span>Tech Event</span>
              <h2>
                The gathering of Highly skilled Developers under a roof: Boom!
              </h2>
            </div>
          </div>
        </div>
      </section> */}
      <section className={styles.allTalents}>
        <h1>Workova Talents</h1>

        <div className={styles.lists}>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
        </div>
        <div className={styles.seperator} />
        <div className={styles.lists}>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
            <li>
              <Link to="#">Android Developers</Link>
            </li>
          </ul>
        </div>
      </section>
      <OtherFooter />
    </div>
  );
}
