import React, { ElementType, FunctionComponent } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import DashNav from "Components/DashNav";
import Main from './Main';
import FreelancerSearch from './FindFreelancers';

interface RouteElement {
  path: string;
  exact: boolean;
  Child: ElementType;
  title: string;
}

const routes: RouteElement[] = [
  {
    path: "",
    exact: true,
    Child: Main,
    title: "Dashboard"
  },
  {
    path: "findFreelancer",
    exact: true,
    Child: FreelancerSearch,
    title: "Find Freelancers"
  },
];

const MainDashboard: FunctionComponent = () => {
  const { path: pathName } = useRouteMatch();
  
  return (
    <Switch>
      {routes.map(({ path, exact, Child, title }, index) => (
        <Route
        exact={exact}
        path={`${pathName}/${path}`}
        key={`${pathName}/${path}/${index}`}
        >
          {/* <DashNav name={title} /> */}
          <Child />
        </Route>
      ))}
    </Switch>
  );
};

export default MainDashboard;
