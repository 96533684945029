import React from "react";
import bellIcon from "Assets/images/bell.svg";
import exportIcon from "Assets/images/export.svg";
import styles from "./navbar.module.scss";
import { ReactComponent as LeftArrow } from "Assets/svg/left__arrow.svg";
import { useHistory } from "react-router-dom";

interface Props {
  name: string;
}

const DashNav = ({ name }: Props) => {
  const history = useHistory();

  return (
    <nav className={styles.nav}>
      <div className={styles.left}>
        <button className={styles.leftArrow} onClick={() => history.goBack()}>
          <LeftArrow />
        </button>
        <h2>{name.length ? name : "Dashboard"}</h2>
      </div>
      <div className={styles.right}>
        <button className={styles.notify}>
          <img src={bellIcon} alt="bell" />
          <span>10</span>
        </button>
        <div className={styles.seperator} />

        <button className={styles.export}>
          <img src={exportIcon} alt="export" />
        </button>
      </div>
    </nav>
  );
};

export default DashNav;
