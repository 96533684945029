import { format } from "date-fns";

export function calculateWeeksFrom(inputDate: any) {
  const period = new Date(inputDate);
  const today = new Date();
  let difference =
    (period.getTime() - today.getTime()) / 1000 / (60 * 60 * 24 * 7);

  return Math.abs(Math.round(difference));
}

export function calculateDueDateFromWeeks(weeksFromNow: number = 0) {
  if (weeksFromNow > Date.UTC(90, 0)) {
    const todayDate = new Date(weeksFromNow);
    return format(todayDate, "do, LLLL");
  } else {
    let today = new Date();
    today.setDate(today.getDate() + weeksFromNow * 7);
    return format(today, "do, LLLL");
  }
}
