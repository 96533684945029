import React from "react";
import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import homeIcon from "Assets/images/dashboard.svg";
import updateIcon from "Assets/images/update.svg";
import projectsIcon from "Assets/images/projects.svg";
import videocallIcon from "Assets/images/videocall.svg";
import workspaceIcon from "Assets/images/workspace.svg";
import connectIcon from "Assets/images/connect.svg";
import interviewIcon from "Assets/images/interview.svg";
import feedbackIcon from "Assets/images/feedback.svg";
import giftIcon from "Assets/images/gift.svg";
import coursesIcon from "Assets/images/courses.svg";
import skillsIcon from "Assets/images/skills.svg";
import shareIcon from "Assets/images/share.svg";
import rating from "Assets/images/rating.svg";
import { useSelector } from "react-redux";

interface Props {}

const routes: any = {
  admin: [
    {
      link: "main",
      name: "Dashboard",
    },
    {
      link: "interview",
      name: "Interviews",
    },
    {
      link: "recruiters",
      name: "Recruiters",
    },
    {
      link: "workspaces",
      name: "Workspaces",
    },
    {
      link: "freelancers",
      name: "Freelancers",
    },
    {
      link: "courses",
      name: "Courses",
    },
    {
      link: "skills",
      name: "Skills",
    },
    {
      link: "product-updates",
      name: "Product Updates",
    },
  ],
  recruiter: [
    {
      link: "main",
      name: "Dashboard",
    },
    {
      link: "interview",
      name: "Interviews",
    },
    {
      link: "projects",
      name: "Projects",
    },
    {
      link: "workspaces",
      name: "Workspaces",
    },
    {
      link: "connections",
      name: "Connections",
    },
    {
      link: "video-calls",
      name: "Video Calls",
    },
    {
      link: "invites",
      name: "Invite a Friend",
    },
    {
      link: "feedback",
      name: "Feedback",
    },
    {
      link: "product-updates",
      name: "Product Updates",
    },
  ],
  freelancer: [
    {
      link: "main",
      name: "Dashboard",
    },
    {
      link: "projects",
      name: "Projects",
    },
    {
      link: "workspaces",
      name: "Workspaces",
    },
    {
      link: "interview-request",
      name: "Interview Request",
    },

    {
      link: "connections",
      name: "Connections",
    },
    {
      link: "video-calls",
      name: "Video Calls",
    },
    {
      link: "courses",
      name: "Courses",
    },
    {
      link: "invites",
      name: "Invite a Friend",
    },
    {
      link: "feedback",
      name: "Feedback",
    },
    {
      link: "product-updates",
      name: "Product Updates",
    },
  ],
};

const imagesMap: any = {
  Dashboard: homeIcon,
  "Product Updates": updateIcon,
  Projects: projectsIcon,
  Recruiters: projectsIcon,
  "Interview Request": interviewIcon,
  Interviews: interviewIcon,
  Connections: connectIcon,
  Freelancers: connectIcon,
  Courses: coursesIcon,
  Workspaces: workspaceIcon,
  "Video Calls": videocallIcon,
  "Invite a Friend": giftIcon,
  Feedback: feedbackIcon,
  Skills: skillsIcon,
};

const SideBar = (props: Props) => {
  const user = useSelector(({ userReducer }: any) => userReducer.user);
  return (
    <div className={styles.sidebar}>
      <div className={styles.avatar}></div>
      <h2 className={styles.name}>
        {user.firstName} {user.lastName}
      </h2>
      {!user.isRecruiter ? (
        <>
          <img className={styles.rating} src={rating} alt="rating" />
          <button className={styles.share}>
            <img src={shareIcon} alt="share" /> Share Profile
          </button>
        </>
      ) : (
        <button className={styles.viewP}>View Profile</button>
      )}

      <div className={styles.linkContainer}>
        <div className={styles.links}>
          {routes[user.isRecruiter ? "recruiter" : "freelancer"].map(
            (route: { name: string; link: string }, i: number) => (
              <NavLink
                key={i}
                activeClassName={styles.active}
                exact={!route.link.length}
                to={`/dashboard/${route.link}`}
              >
                <span>
                  <img src={imagesMap[route.name]} alt={route.name} />
                </span>
                {route.name}
              </NavLink>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
