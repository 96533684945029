import React, { ReactType, useState, ReactNode } from "react";
import style from "./routetab.module.scss";

interface TabProps {
  name: string;
  component: ReactType;
}

interface Props {
  tabs: TabProps[];
}

const ValueTab = ({ tabs }: Props) => {
  const [current, setCurrent] = useState<any>(0);
  const Component = tabs[current] && tabs[current].component;
  console.log(tabs[current], current);

  return (
    <div className={style.routetab}>
      <div className={style.nav}>
        {tabs.map((tab, i) => (
          <a
            href="#"
            className={i === current ? style.active : ""}
            onClick={() => setCurrent(i)}
          >
            {tab.name}
          </a>
        ))}
      </div>
      <div className={style.content}>
        <Component />
      </div>
    </div>
  );
};

export default ValueTab;
