import React from "react";
import style from "./button.module.scss";
import chevI from "Assets/images/chev-right.svg";
import chevIm from "Assets/images/chevsm.svg";
import attach from "Assets/images/attach.png";

interface Props {
  text: string;
  styles?: Object;
  full?: boolean;
  small?: boolean;
  invert?: boolean;
  red?: boolean;
  green?: boolean;
  chev?: boolean;
  medium?: boolean;
  big?: boolean;
  action: () => void;
  sideIcon?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const Button = ({
  text,
  action,
  styles,
  full,
  red,
  chev,
  medium,
  small,
  big,
  sideIcon,
  invert,
  green,
  disabled = false,
  loading,
}: Props) => {
  return (
    <button
      className={`${style.button} ${full ? style.full : ""}
       ${invert ? style.invert : ""}
       ${red ? style.red : ""}
       ${green ? style.green : ""}
       ${medium ? style.medium : ""}
       ${big ? style.big : ""}
       ${small ? style.small : ""}
       ${disabled ? style.disabled : ""}
       `}
      style={styles}
      onClick={action}
      disabled={disabled}
    >
      {sideIcon ? <img className={style.attach} src={attach} alt="" /> : null}{" "}
      {loading ? "loading..." : text}{" "}
      {chev ? (
        big ? (
          <img src={chevI} alt="chev icon" />
        ) : (
          <img src={chevIm} alt="chev icon" />
        )
      ) : null}
    </button>
  );
};

export default Button;
