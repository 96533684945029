import { USER_PROJECTS_REQUEST, USER_PROJECTS_FAILURE, USER_PROJECTS_SUCCESS, NEW_PROJECTS_REQUEST, NEW_PROJECTS_FAILURE, NEW_PROJECTS_SUCCESS, GET_PROJECT_DETAILS, GET_PROJECT_SUCCESS, GET_PROJECT_FAILURE } from "Store/constants/project.contants";

interface ProjectProps {
  projects: Object;
  loading: boolean;
  error: Object | null;
  createError: Object | null;
  createLoading: boolean;
  singleProject: Object | null;
}
const initialState: ProjectProps = {
  projects: {},
  loading: false,
  error: null,
  createError: null,
  createLoading: false,
  singleProject: {},
};

export default function projectReducer(state = initialState, action: any): any {
  switch (action.type) {
    case USER_PROJECTS_REQUEST:
    case GET_PROJECT_DETAILS:
      return { ...state, loading: true };
    case USER_PROJECTS_FAILURE:
    case GET_PROJECT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case USER_PROJECTS_SUCCESS:
      return { ...state, loading: false, projects: action.payload };

    case NEW_PROJECTS_REQUEST:
      return { ...state, createLoading: true };
    case NEW_PROJECTS_FAILURE:
      return { ...state, createLoading: false, createError: action.payload };
    case NEW_PROJECTS_SUCCESS:
      return { ...state, createLoading: false };

    case GET_PROJECT_SUCCESS:
      return { ...state, loading: false, singleProject: action.payload }

    default:
      return state;
  }
}