import React from 'react';
import Select from "react-select";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import Input from "Components/Input";
import FormGrid from "Components/FormGrid";
import FormError from 'Components/FormError';
import { FormProps } from '.';

export const projectTypes = [
  { value: "New project", label: "New Project"},
  { value: "Existing project", label: "Existing project"},
  { value: "Ongoing assistance/consultation", label: "Ongoing assistance/consultation"},
  { value: "Others", label: "Others"},
];

export const specifications = [
  { value: "I have a rough idea", label: "I have a rough idea"},
  { value: "I have specifications ready", label: "I have specifications ready"},
  { value: "I have delivery expectations", label: "I have delivery expectations"},
  { value: "I'll decide later", label: "I'll decide later"},
];

interface SelectOption {
  value: string;
  label: string;
}

const PageOne = () => {
  const formContext = useFormikContext<FormProps>();
  const { values, handleChange, setFieldValue, errors, touched } = formContext;

  return (
    <FormGrid>
      <div>
        <Input
          value={values.firstName}
          name="firstName"
          action={handleChange}
          placeholder="Your first name"
          label="First name"
        />
        <FormError error={errors.firstName} touched={touched.firstName} />
      </div>
      <div>
        <Input
          value={values.lastName}
          name="lastName"
          action={handleChange}
          placeholder="Enter last name"
          label="Last name"
        />
        <FormError error={errors.lastName} touched={touched.lastName} />
      </div>
      <div>
        <Input
          value={values.email}
          name="email"
          action={handleChange}
          placeholder="you@example.com"
          label="Email"
          margin
        />
        <FormError error={errors.email} touched={touched.email} />
      </div>
      <div>
        <Input
          value={values.password}
          name="password"
          action={handleChange}
          placeholder="Choose a password"
          label="Password"
          margin
          password
        />
        <FormError error={errors.password} touched={touched.password} />
      </div>
      <div>
        <Input
          value={values.companyName}
          name="companyName"
          action={handleChange}
          placeholder="Your company name"
          label="Company name"
        />
        <FormError error={errors.companyName} touched={touched.companyName} />
      </div>
      <div>
        <label>
          What type of project are you hiring for?
        </label>
        <Select
          onChange={(value) => setFieldValue('projectType', (value as SelectOption).value)}
          className="basic-single"
          classNamePrefix="select"
          name="color"
          options={projectTypes}
          styles={{
            input: () => ({
              outline: "none",
            }),
            singleValue: (base) => ({
              borderRadius: "5px",
              fontSize: "14px",
              color: '#0251bc',
              lineHeight: "2.2"
            }),
            placeholder: () => ({
              lineHeight: "2.2",
            }),
            indicatorSeparator: () => ({
              width: 0,
            }),
            valueContainer: (base) => ({
              ...base,
              padding: '0.375rem 1.2rem'
            }),
            control: (base, state) => ({
              ...base,
              backgroundColor: '#fff',
              border: '1px solid #c9d7eb',
              color: '#73859e',
              borderRadius: '5px',
              fontSize: '14px',
              marginTop: '10px',
              outline: 'none',
              '&:focus': {
                color: '#0251bc',
                border: '1px solid #0251bc',
                boxShadow: 'none'
              },
              '&:focus, &:hover': {
                userSelect: 'auto',
                border: '1px solid #c9d7eb',
              },
              '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
                border: '1px solid #c9d7eb',
                boxShadow: '0 0 0px 1000px #fff inset',
                backgroundColor: '#fff',
                background: '#fff',
                fontSize: '18px',
                color: '#73859e',
              }
            }),
          }}
        />
        <FormError error={errors.projectType} touched={touched.projectType} />
      </div>
      <div
        style={{
          margin: '25px 0',
        }}
      >
        <label>
          Do you have specifications ready?
        </label>
        <Select
          onChange={(value) => setFieldValue('specification', (value as SelectOption).value)}
          className="basic-single"
          classNamePrefix="select"
          name="color"
          options={specifications}
          styles={{
            input: () => ({
              outline: "none",
            }),
            singleValue: (base) => ({
              borderRadius: "5px",
              fontSize: "14px",
              color: '#0251bc',
              lineHeight: "2.2"
            }),
            placeholder: () => ({
              lineHeight: "2.2",
            }),
            indicatorSeparator: () => ({
              width: 0,
            }),
            valueContainer: (base) => ({
              ...base,
              padding: '0.375rem 1.2rem'
            }),
            control: (base, state) => ({
              ...base,
              backgroundColor: '#fff',
              border: '1px solid #c9d7eb',
              color: '#73859e',
              borderRadius: '5px',
              fontSize: '14px',
              marginTop: '10px',
              outline: 'none',
              '&:focus': {
                color: '#0251bc',
                border: '1px solid #0251bc',
                boxShadow: 'none'
              },
              '&:focus, &:hover': {
                userSelect: 'auto',
                border: '1px solid #c9d7eb',
              },
              '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
                border: '1px solid #c9d7eb',
                boxShadow: '0 0 0px 1000px #fff inset',
                backgroundColor: '#fff',
                background: '#fff',
                fontSize: '18px',
                color: '#73859e',
              }
            }),
          }}
        />
        <FormError error={errors.specification} touched={touched.specification} />
      </div>
      <div>
        <Input
          value={values.budget}
          name="budget"
          action={setFieldValue}
          placeholder="E.g. $2,500"
          label="What is your budget?"
          margin
          number
        />
        <FormError error={errors.budget} touched={touched.budget} />
      </div>
    </FormGrid>
  );
}

export default PageOne;