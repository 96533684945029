import React, {
  useState
} from "react";
import styles from "./newDispute.module.scss";
import Button from "Components/Button";
import Input from "Components/Input";
import Textarea from "Components/Textarea";
import ProfileRecommendation from 'Components/ProfileRecommendation';
import { useHistory } from "react-router-dom";

interface Dispute {
  title: string;
  description: string
}

const NewDispute = () => {
  const [state, setState] = useState<Dispute>({ title: "", description: "" });
  const history = useHistory();

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

  return (
    <>
      <div className={styles.newDispute}>
        <div className={styles.top}>
          <Button
            small
            text="Add New Project"
            action={() => history.push("/dashboard/projects/new")}
            styles={{marginRight: "14px"}}
          />
          <Button
            small
            text="Find Freelancers"
            action={() => null}
          />
        </div>
        <div className={styles.midSection}>
          <Input
            name="title"
            action={setValue}
            value={state.title}
            placeholder="Type a feedback topic"
          />
          <Textarea
            label="Enter dispute description"
            name="description"
            action={setValue}
            value={state.description}
            margin
          />
          <div className={styles.actions}>
            <Button
              small
              sideIcon
              styles={{
                color: "#0251BC",
                backgroundColor: "#F5FCFF",
                width: "25%"
              }}
              text="Click to attach files"
              action={() => null}
            />
            <Button
              small
              text="Submit"
              action={() => null}
              styles={{
                width: "25%"
              }}
            />
          </div>
        </div>
        <ProfileRecommendation />
      </div>
    </>
  );
};

export default NewDispute;
