import React from "react";
import style from "./index.module.scss";
import flag from "Assets/images/flag2.svg";

interface Props {
  backImg: string;
  date:string;
  title: string;
  image: string;
  name: string;
  country: string;
  action?: () => void;
  styles?: Object;
}

const BackCard = ({
  backImg,
  date,
  title,
  image,
  name,
  country,
  action,
  styles,
}: Props) => {
  return (
    <div className={style.card}>
      <div className={style.backdrop}>
        <img src={backImg} alt="backdrop" />
      </div>
      <div className={style.topleft}>{date}</div>
      <div className={style.content}>
        <h1>{title}</h1>
        <div className={style.profile}>
          <div>
            <img className={style.avatar} src={image} alt="author"/>
          </div>
          <div className={style.user}>
            <h2>{name}</h2>
            <span className={style.other}>
              <img src={flag} alt="flag" />
              <span>{country}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackCard;
