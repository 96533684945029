import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import Button from "Components/Button";
import Input from "Components/Input";

interface SignState {
  country: string;
  skill: string;
}

export const colourOptions = [
  { value: "Select", label: "Select" },
  { value: "NodeJs", label: "NodeJs" },
  { value: "PHP", label: "PHP" },
  { value: "ReactJs", label: "ReactJs" },
  { value: "Canva", label: "Canva" },
  { value: "VueJs", label: "VueJs" },
  { value: "Angular", label: "Angular" },
];

const HireSignup2 = () => {
  const history = useHistory();

  const [state, setState] = useState<SignState>({ country: "", skill: "" });
  const [remember, setRemember] = useState(false);

  // const { loading } = useSelector(({ userReducer }: any) => ({
  //   loading: userReducer.loading,
  // }));

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

    return (
      <div className={styles.hirer}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="logo" />
        </Link>

        <h3 className={styles.welcome}>Hire a talent, get your projects delivered</h3>

        <form autoComplete="off" className={styles.form}>
          <div>
            <p
              style={{
                fontSize: "13px",
                color: "#042757",
                marginBottom: "10px",
              }}
            >
              Select Country
            </p>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={colourOptions[0]}
              name="color"
              options={colourOptions}
              styles={{
                input: () => ({
                  // marginTop : "10px",
                  // border: "1px solid #c9d7eb",
                  outline: "none",
                  padding: "10px 0",
                }),
                singleValue: (base) => ({
                  borderRadius: "5px",
                  fontSize: "14px",
                }),
              }}
            />
          </div>
          <Input
            value={state.skill}
            name="skills"
            action={setValue}
            placeholder="E.g: Node Js"
            label="Type a skill you need for this project"
            margin
          />
          <div className={styles.middle}>
            <p className={styles.tags}>Angular Js</p>
            <p className={styles.tags}>Node Js</p>
            <p className={styles.tags}>PHP</p>
            <p className={styles.tags}>ReactJs</p>
            <p className={styles.tags}>Product Management</p>
          </div>
          <Button
            full
            styles={{
              padding: "15px 50px",
              marginTop: "10%",
              marginBottom: "5%",
            }}
            text="Join Workova"
            // loading={loading}
            action={() => null}
          />
        </form>
        <p className={styles.footer}>
          <Link to="/auth/signin">Have an account? Sign In</Link>
        </p>
      </div>
    );
};

export default HireSignup2;
