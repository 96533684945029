import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import styles from "./style.module.scss";
import logo from "Assets/images/logo.svg";
import Button from "Components/Button";
import Input from "Components/Input";
import FormGrid from "Components/FormGrid";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "Store/actions/user.action";
import { basename } from "path";
interface HireSignupState {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  companyName: string;
  budget: number;
  projectType: string;
  specification: string;
}

export const colourOptions = [
  { value: "Select", label: "Select" },
  { value: "NodeJs", label: "NodeJs" },
  { value: "PHP", label: "PHP" },
  { value: "ReactJs", label: "ReactJs" },
  { value: "Canva", label: "Canva" },
  { value: "VueJs", label: "VueJs" },
  { value: "Angular", label: "Angular" },
];

export default function HireSignup() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState<HireSignupState>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    companyName: "",
    budget: 0,
    projectType: "",
    specification: "",
  });
  const { loading } = useSelector(({ userReducer }: any) => ({
    loading: userReducer.loading,
  }));
  const signUpAction = (e?: Event) => {
    e && e.preventDefault();
    dispatch(
      registerUser({ ...state, isRecruiter: true }, () =>
        history.push("/auth/activation")
      )
    );
  };
  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  return (
    <div className={styles.HireSignup}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <h3 className={styles.welcome}>
        Hire a talent, get your projects delivered
      </h3>

      <form autoComplete="off" className={styles.form}>
        <FormGrid>
          <Input
            value={state.firstName}
            name="firstName"
            action={setValue}
            placeholder="Your first name"
            label="First name"
          />
          <Input
            value={state.lastName}
            name="lastName"
            action={setValue}
            placeholder="Enter last name"
            label="Last name"
          />
        </FormGrid>
        <FormGrid>
          <Input
            value={state.email}
            name="email"
            action={setValue}
            placeholder="you@example.com"
            label="Email"
            margin
          />
          <Input
            value={state.password}
            name="password"
            action={setValue}
            placeholder="Choose a password"
            label="Password"
            margin
            password
          />
        </FormGrid>
        <FormGrid>
          <Input
            value={state.companyName}
            name="companyName"
            action={setValue}
            placeholder="Your company name"
            label="Company name"
          />
          <div>
            <p
              style={{
                fontSize: "13px",
                color: "#042757",
                marginTop: "5px",
              }}
            >
              What type of project are you hiring for?
            </p>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={colourOptions[0]}
              name="color"
              options={colourOptions}
              styles={{
                input: () => ({
                  // marginTop : "10px",
                  // border: "1px solid #c9d7eb",
                  outline: "none",
                  padding: "10px 0",
                }),
                singleValue: (base) => ({
                  borderRadius: "5px",
                  fontSize: "14px",
                }),
              }}
            />
          </div>
        </FormGrid>
        <FormGrid>
          <div>
            <p
              style={{
                fontSize: "13px",
                color: "#042757",
                marginTop: "5px",
              }}
            >
              Do you have specifications ready?
            </p>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={colourOptions[0]}
              name="color"
              options={colourOptions}
              styles={{
                input: () => ({
                  // marginTop : "10px",
                  // border: "1px solid #c9d7eb",
                  outline: "none",
                  padding: "10px 0",
                }),
                singleValue: (base) => ({
                  borderRadius: "5px",
                  fontSize: "14px",
                }),
              }}
            />
          </div>
          <Input
            value={state.budget.toString()}
            name="budget"
            action={(value) =>
              setState({
                ...state,
                budget: value,
              })
            }
            placeholder="E.g. $2,500"
            label="What is your budget?"
            margin
            number
          />
        </FormGrid>

        <Button
          full
          styles={{
            padding: "15px 50px",
            marginTop: "20px",
            marginBottom: "3%",
          }}
          loading={loading}
          disabled={
            (!state.email &&
              !state.password &&
              !state.firstName &&
              !state.specification &&
              !state.budget &&
              !state.projectType &&
              !state.companyName &&
              !state.lastName) ||
            loading
          }
          text="Join Workova"
          action={signUpAction}
        />
      </form>
      <p className={styles.footer}>
        <Link to="/auth/signin">Have an account? Sign In</Link>
      </p>
    </div>
  );
}
