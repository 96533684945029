import React from "react";
import logo from "Assets/images/logo.svg";
import styles from "./navbar.module.scss";
import { NavLink, Link } from "react-router-dom";

interface Props {}

const Navbar = (props: Props) => {
  return (
    <nav className={styles.nav}>
      <div className={styles.left}>
        <NavLink className={styles.logo} to="/">
          <img src={logo} alt="logo" />
        </NavLink>
        <ul>
          <li>
            <NavLink to="/about" activeClassName={styles.active} exact>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" activeClassName={styles.active}>
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink to="/workspaces" activeClassName={styles.active} exact>
              Workspaces
            </NavLink>
          </li>
          <li>
            <NavLink to="/courses" activeClassName={styles.active} exact>
              Courses
            </NavLink>
          </li>
          <li>
            <NavLink to="/events" activeClassName={styles.active} exact>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/pricing" activeClassName={styles.active} exact>
              Pricing
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/verified-freelancers"
              activeClassName={styles.active}
              exact
            >
              Verified Freelancers
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.right}>
        <Link to="auth/signin">Sign In</Link>
        <div className={styles.seperator} />
        <Link className={styles.btn} to="/auth/hire-talent-1">
          Post A Job
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
