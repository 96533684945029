import projectReducer from "./project.reducer";
import userReducer from "./user.reducer";
import networkReducer from "./network.reducer";
import { combineReducers } from "redux";
import skillReducer from "./skill.reducer";

const rootReducer = combineReducers({
  projectReducer,
  userReducer,
  networkReducer,
  skillReducer,

});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
