import React, { useState, useEffect } from "react";
import styles from "./projectsAll.module.scss";
import pro1 from "Assets/images/projectcard/Rectangle 9 (4).png";
import pro2 from "Assets/images/projectcard/Rectangle 9 (2).png";
import pro3 from "Assets/images/projectcard/Rectangle 9 (1).png";
import pro4 from "Assets/images/projectcard/Rectangle 9 (3).png";
import pro5 from "Assets/images/projectcard/Rectangle 9 (5).png";
import pro6 from "Assets/images/projectcard/Rectangle 9.png";
import authorPic from "Assets/images/projectcard/Ellipse 18.png";
import search from "Assets/images/projectcard/search.png";
import walletEmpty from "Assets/svg/wallet-empty.svg";
import ProjectCard from "Components/ProjectCard";
import Button from "Components/Button";
import arrowLeft from "Assets/images/projectcard/arrow.png";
import arrowRight from "Assets/images/projectcard/arrow1.png";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProjects } from "Store/actions/project.action";

interface Search {
  text: string;
}

const ProjectAll = () => {
  const [state, setState] = useState<Search>({ text: "" });
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, projects } = useSelector(
    ({ userReducer, projectReducer }: any) => ({
      user: userReducer.user,
      projects: projectReducer.projects,
    })
  );
  const networkOnline = useSelector(
    ({ networkReducer }: any) => networkReducer.networkOnline
  );

  useEffect(() => {
    if (networkOnline && user.account)
      dispatch(fetchUserProjects(user.account));
  }, [networkOnline, user.account, dispatch]);

  const setValue = (e: any) =>
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });

  return projects.data && projects.data.length ? (
    <>
      <div className={styles.allproject}>
        <div className={styles.actions}>
          <Button
            small
            styles={{
              color: "#0251BC",
              backgroundColor: "transparent",
              border: "1px solid #0251BC",
              marginRight: "12px",
            }}
            text="Find Freelancers"
            action={() => null}
          />
          <Button
            small
            text="Add New Project"
            action={() => history.push("/dashboard/projects/new")}
          />
        </div>
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.search}>
              <input
                name="text"
                type="text"
                onChange={setValue}
                value={state.text}
                placeholder="Find project by name/number"
              />
              <img src={search} alt="find" className={styles.inGlass} />
            </div>
            <p className={styles.value}>Total projects valued: $200,000.00</p>
          </div>
          <div className={styles.cards}>
            {projects.data.map((project: any) => (
              <ProjectCard
                action={() =>
                  history.push(`/dashboard/projects/${project._id}`)
                }
                image={pro1}
                title={project.title}
                due="Due April 4th"
                author={authorPic}
                name="Jody Booms"
                location="USA"
              />
            ))}
          </div>
          <div className={styles.paginate}>
            <div className={styles.numbers}>
              <p>1</p>
              <p>2</p>
              <p>3</p>
              <p>4</p>
              <p>5</p>
              <p>...</p>
            </div>
            <div className={styles.navarrow}>
              <div className={styles.arrow}>
                <img src={arrowLeft} alt="arrows" />
              </div>
              <div className={styles.arrow}>
                <img src={arrowRight} alt="arrows" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : networkOnline ? (
    <div className={styles.empty}>
      <img src={walletEmpty} alt="empty project" />
      <p>
        Your projects will be displayed here. You have not created a project
        yet. Please add a new project
      </p>
      <footer>
        <Button
          medium
          styles={{
            color: "#0251BC",
            backgroundColor: "transparent",
            border: "1px solid #0251BC",
            marginRight: "12px",
          }}
          text="Find Freelancers"
          action={() => null}
        />
        <Button
          medium
          text="Add New Project"
          action={() => history.push("/dashboard/projects/new")}
        />
      </footer>
    </div>
  ) : (
    <div className={styles.empty}>
      <img src={walletEmpty} alt="empty project" />
      <p>
        Your projects will be displayed here. None showed because of Network.
        Please connect to the Internet
      </p>
    </div>
  );
};

export default ProjectAll;
