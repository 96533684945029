import actionCreator from "Utils/actionCreator";
import Axios from "axios";
import {
    USER_SIGN_IN_REQUEST,
    USER_SIGN_IN_FAILURE,
    USER_SIGN_IN_SUCCESS,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAILURE,
} from "Store/constants/user.contants";

export const registerUser = (
    {
        firstName,
        lastName,
        email,
        password,
        companyName,
        projectType,
        specification,
        budget,
        isRecruiter,
    }: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        companyName?: string;
        projectType?: string;
        specification?: string;
        budget?: number;
        isRecruiter: boolean;
    },
    action?: () => void,
    errorAction?: () => void,
) => async (dispatch: any) => {
    dispatch(actionCreator(USER_REGISTER_REQUEST));
    try {
        const { data: response } = await Axios.post("/register", {
            firstName,
            lastName,
            email,
            password,
            isRecruiter,
            ...isRecruiter && {
                companyName,
                projectType,
                specification,
                budget,
            }
        });
        if (response.status === "success") {
            dispatch(
                actionCreator(USER_REGISTER_SUCCESS, {
                    ...response.data,
                })
            );

            action && action();
        }
    } catch (error) {
        console.log(error);
        errorAction && errorAction();
        dispatch(actionCreator(USER_REGISTER_FAILURE));
    }
};

export const loginUser = (
    { email, password }: { email: string; password: string },
    action?: () => void,
    errorAction?: () => void,
) => async (dispatch: any) => {
    dispatch(actionCreator(USER_SIGN_IN_REQUEST));
    try {
        const { data: response } = await Axios.post("/login?populate=role", {
            email,
            password,
        });
        if (response.status === "success") {
            const workovaJWT = `Bearer ${response.data.workovaJWT}`;
            const giroToken = response.data.giroToken;
            const userDetail = {
                ...response.data.user,
                account: response.data.account,
                giroId: response.data.giroId,
                giroAccountId: response.data.giroAccountId,
                email: response.data.email,
            };
            Axios.defaults.headers.common["girotoken"] = giroToken;
            Axios.defaults.headers.common["Authorization"] = workovaJWT;
            localStorage.setItem("user", JSON.stringify(userDetail));
            localStorage.setItem("giroToken", giroToken);
            localStorage.setItem("workovaJWT", workovaJWT);

            dispatch(
                actionCreator(USER_SIGN_IN_SUCCESS, userDetail)
            );
            action && action();
        }
    } catch (error) {
        console.log(error);
        errorAction && errorAction();
        dispatch(actionCreator(USER_SIGN_IN_FAILURE));
    }
};
