import actionCreator from "Utils/actionCreator";
import Axios from "axios";
import { USER_PROJECTS_REQUEST, USER_PROJECTS_FAILURE, USER_PROJECTS_SUCCESS, NEW_PROJECTS_REQUEST, NEW_PROJECTS_SUCCESS, NEW_PROJECTS_FAILURE, GET_PROJECT_DETAILS, GET_PROJECT_SUCCESS, GET_PROJECT_FAILURE } from "Store/constants/project.contants";
import { Dispatch } from "redux";


export const fetchUserProjects = (
    accountId: string,
    action?: () => void,
    errorAction?: () => void,
) => async (dispatch: any) => {
    dispatch(actionCreator(USER_PROJECTS_REQUEST));
    try {
        const { data: response } = await Axios.get(`/projects?owner=${accountId}&populate=user`);
        if (response.status === "success") {
            const { status, ...rest } = response;
            const projects = {
                ...rest,
            };

            dispatch(
                actionCreator(USER_PROJECTS_SUCCESS, projects)
            );
            action && action();
        }
    } catch (error) {
        console.log(error);
        errorAction && errorAction();
        dispatch(actionCreator(USER_PROJECTS_FAILURE));
    }
};
export const createUserProjects = (
    accountId: string,
    details: {
        title: string;
        requiredSkills: string;
        description: string;
        payoutAmount: string;
        deliveryExpectation: string;
        additionalDeliveryExpectation: string;
        dueDate: string;
        files: File | null,
    },
    action?: () => void,
    errorAction?: () => void,
) => async (dispatch: any) => {
    dispatch(actionCreator(NEW_PROJECTS_REQUEST));
    try {
        const formData = new FormData();
        Object.entries(details).forEach(([field, value]) => {
            if(value) {
                formData.append(field, value);
            }
        })

        const { data: response } = await Axios.post(`/projects?accountId=${accountId}`, formData);
        if (response.status === "success") {
            const { status, ...rest } = response;
            const projects = {
                ...rest,
            };

            dispatch(
                actionCreator(NEW_PROJECTS_SUCCESS, projects)
            );
            action && action();
        }
    } catch (error) {
        console.log(error);
        errorAction && errorAction();
        dispatch(actionCreator(NEW_PROJECTS_FAILURE));
    }
};


export const getProjectDetails = (projectId: string, accountId: string, action?: () => void, errorAction?: () => void,) => async (dispatch: Dispatch) => {
    dispatch(actionCreator(GET_PROJECT_DETAILS));
    try {
        const { data: response } = await Axios.get(`/projects/${projectId}?accountId=${accountId}&populate=requiredSkills`);
        if (response.status === "success") {
            const { status, ...rest } = response;

            dispatch(
                actionCreator(GET_PROJECT_SUCCESS, response.data)
            );
            action && action();
        }
    } catch (error) {
        console.log(error);
        errorAction && errorAction();
        dispatch(actionCreator(GET_PROJECT_FAILURE));
    }
}
