import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./maindash.module.scss";
import Button from "Components/Button";
import wallet from "Assets/images/wallet.svg";
import piggy from "Assets/images/piggy.svg";
import ProjectSearchBar from "Components/ProjectSearchBar";
import verify from "Assets/svg/verify.svg";
import ReactImg from "Assets/images/react.png";
import LaravelImg from "Assets/images/laravel.png";
import NodeImg from "Assets/images/nodejs.png";
import PythonImg from "Assets/images/python.png";
import img1 from "Assets/images/lilProjimg.png";
import authorPic from "Assets/images/Ellipse 17.png";
import SkillIcon from "Pages/Public/Talent/atom/SkillIcon";
import { ISkillItem } from "Pages/Public/Talent/models";
import ProjectCard, { CardItem } from "../atom/ProjectCard";
import arrowLeft from "Assets/images/projectcard/arrow.png";
import arrowRight from "Assets/images/projectcard/arrow1.png";
import chev from "Assets/images/chevblue.svg";
import avatar from "Assets/images/avatar.svg";
import InterviewCard, { InterviewItem } from "../atom/InterviewCard";
import ValueTab from "Components/ValueTab";

const skillArray: ISkillItem[] = [
  { skillLogo: PythonImg, name: "Python", small: true },
  { skillLogo: NodeImg, name: "Node Js", small: true },
  { skillLogo: LaravelImg, name: "Laravel", small: true },
  { skillLogo: ReactImg, name: "React Js", small: true },
];

const interviewRequests: InterviewItem[] = [
  {
    avatar: avatar,
    name: "Jay The Recruiter",
    time: "2 days ago",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    avatar: avatar,
    name: "Jay The Recruiter",
    time: "2 days ago",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    avatar: avatar,
    name: "Jay The Recruiter",
    time: "2 days ago",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    avatar: avatar,
    name: "Jay The Recruiter",
    time: "2 days ago",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
  {
    avatar: avatar,
    name: "Jay The Recruiter",
    time: "2 days ago",
    message: "Hi, can we have a chat by 12noon WAT...",
  },
];

const ProjectCardArray: CardItem[] = [
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    date: "April 4",
    wall: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    date: "April 4",
    wall: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    date: "April 4",
    wall: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    date: "April 4",
    wall: true,
  },
  {
    image: img1,
    title: "The new musical app in the industry",
    author: authorPic,
    name: "Adeosun Babra",
    location: "Brazil",
    date: "April 4",
    wall: true,
  },
];

export default function MainDashboard() {
  const history = useHistory();

  return (
    <main className={styles.dashboard}>
      <section className={styles.top}>
        <div className={styles.upper}>
          <div className={styles.left}>
            <div className={styles.balance}>
              <img className={styles.wallet} src={wallet} alt="wallet" />
              Balance: NGN1,682,000.00
              <img className={styles.pig} src={piggy} alt="keep" />
            </div>
            <Button
              small
              text="Transactions"
              action={() => null}
              styles={{
                color: "#0251bc",
                background: "#e2f6ff",
                padding: "13px 25px",
                marginLeft: "15px",
              }}
            />
          </div>
          <div className={styles.right}>
            <Button
              small
              text="Find Freelancers"
              action={() => history.push("/dashboard/main/findFreelancer")}
              styles={{
                padding: "13px 30px",
              }}
            />
            <Button
              small
              text="Find Projects"
              action={() => null}
              styles={{
                padding: "13px 35px",
                marginLeft: "15px",
              }}
            />
          </div>
        </div>
        <div className={styles.search}>
          <p>Find project</p>
          <ProjectSearchBar />
        </div>
        <div className={styles.bottom}>
          <div className={styles.left}>
            <p>You're now a verified freelancer</p>
            <img src={verify} alt="badge" />
          </div>
          <div className={styles.right}>
            <p>Verified Skills</p>
            <div className={styles.skills}>
              {skillArray.map((skill1, index) => (
                <SkillIcon {...skill1} key={index} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className={styles.middle}>
        <p>Projects</p>

        {/* <div
          style={{
            width: "8vw",
          }}
        > */}
        <ValueTab
          tabs={[
            {
              name: "Software Development",
              component: () => (
                <div className={styles.projectBar}>
                  <div className={styles.cards}>
                    {ProjectCardArray.map((projects1, index) => (
                      <ProjectCard {...projects1} key={index} />
                    ))}
                  </div>
                  <div className={styles.onit}>
                    <div className={styles.navarrow}>
                      <div className={styles.arrow}>
                        <img src={arrowLeft} alt="arrows" />
                      </div>
                      <div className={styles.arrow}>
                        <img src={arrowRight} alt="arrows" />
                      </div>
                    </div>
                    <Button
                      small
                      text="View More"
                      invert
                      action={() => null}
                      styles={{
                        width: "10%",
                        padding: "10px 0",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              name: "Product Design",
              component: () => (
                <div className={styles.projectBar}>
                  <div className={styles.cards}>
                    {ProjectCardArray.slice(0, 2).map((projects1, index) => (
                      <ProjectCard {...projects1} key={index} />
                    ))}
                  </div>
                  <div className={styles.onit}>
                    <div className={styles.navarrow}>
                      <div className={styles.arrow}>
                        <img src={arrowLeft} alt="arrows" />
                      </div>
                      <div className={styles.arrow}>
                        <img src={arrowRight} alt="arrows" />
                      </div>
                    </div>
                    <Button
                      small
                      text="View More"
                      invert
                      action={() => null}
                      styles={{
                        width: "10%",
                        padding: "10px 0",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              name: "Digital Marketing",
              component: () => (
                <div className={styles.projectBar}>
                  <div className={styles.cards}>
                    {ProjectCardArray.slice(0, 2).map((projects1, index) => (
                      <ProjectCard {...projects1} key={index} />
                    ))}
                  </div>
                  <div className={styles.onit}>
                    <div className={styles.navarrow}>
                      <div className={styles.arrow}>
                        <img src={arrowLeft} alt="arrows" />
                      </div>
                      <div className={styles.arrow}>
                        <img src={arrowRight} alt="arrows" />
                      </div>
                    </div>
                    <Button
                      small
                      text="View More"
                      invert
                      action={() => null}
                      styles={{
                        width: "10%",
                        padding: "10px 0",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              name: "Legal Strategy",
              component: () => (
                <div className={styles.projectBar}>
                  <div className={styles.cards}>
                    {ProjectCardArray.slice(0, 2).map((projects1, index) => (
                      <ProjectCard {...projects1} key={index} />
                    ))}
                  </div>
                  <div className={styles.onit}>
                    <div className={styles.navarrow}>
                      <div className={styles.arrow}>
                        <img src={arrowLeft} alt="arrows" />
                      </div>
                      <div className={styles.arrow}>
                        <img src={arrowRight} alt="arrows" />
                      </div>
                    </div>
                    <Button
                      small
                      text="View More"
                      invert
                      action={() => null}
                      styles={{
                        width: "10%",
                        padding: "10px 0",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              name: "Financial Modelling",
              component: () => (
                <div className={styles.projectBar}>
                  <div className={styles.cards}>
                    {ProjectCardArray.slice(0, 2).map((projects1, index) => (
                      <ProjectCard {...projects1} key={index} />
                    ))}
                  </div>
                  <div className={styles.onit}>
                    <div className={styles.navarrow}>
                      <div className={styles.arrow}>
                        <img src={arrowLeft} alt="arrows" />
                      </div>
                      <div className={styles.arrow}>
                        <img src={arrowRight} alt="arrows" />
                      </div>
                    </div>
                    <Button
                      small
                      text="View More"
                      invert
                      action={() => null}
                      styles={{
                        width: "10%",
                        padding: "10px 0",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              name: "UI/UX",
              component: () => (
                <div className={styles.projectBar}>
                  <div className={styles.cards}>
                    {ProjectCardArray.slice(0, 2).map((projects1, index) => (
                      <ProjectCard {...projects1} key={index} />
                    ))}
                  </div>
                  <div className={styles.onit}>
                    <div className={styles.navarrow}>
                      <div className={styles.arrow}>
                        <img src={arrowLeft} alt="arrows" />
                      </div>
                      <div className={styles.arrow}>
                        <img src={arrowRight} alt="arrows" />
                      </div>
                    </div>
                    <Button
                      small
                      text="View More"
                      invert
                      action={() => null}
                      styles={{
                        width: "10%",
                        padding: "10px 0",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              ),
            },
            {
              name: "Product Management",
              component: () => (
                <div className={styles.projectBar}>
                  <div className={styles.cards}>
                    {ProjectCardArray.slice(0, 2).map((projects1, index) => (
                      <ProjectCard {...projects1} key={index} />
                    ))}
                  </div>
                  <div className={styles.onit}>
                    <div className={styles.navarrow}>
                      <div className={styles.arrow}>
                        <img src={arrowLeft} alt="arrows" />
                      </div>
                      <div className={styles.arrow}>
                        <img src={arrowRight} alt="arrows" />
                      </div>
                    </div>
                    <Button
                      small
                      text="View More"
                      invert
                      action={() => null}
                      styles={{
                        width: "10%",
                        padding: "10px 0",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              ),
            },
          ]}
        />
        {/* </div> */}
      </section>
      <section className={styles.bottom}>
        <div className={styles.head}>
          <p className={styles.intvw}>Interview Requests</p>
          <p className={styles.vwall}>
            View All
            <img src={chev} alt="chev icon" />
            <img src={chev} alt="chev icon" />
          </p>
        </div>
        <div className={styles.contain}>
          {interviewRequests.map((interview1, index) => (
            <InterviewCard {...interview1} key={index} />
          ))}
        </div>
      </section>
    </main>
  );
}
