import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import Navbar from "Components/Navbar";

import { ISkillItem, IProjectCard, ICourseCard } from "./models";
import SkillIcon from "./atom/SkillIcon";
import ProjectCard from "./atom/ProjectCard";
import OtherFooter from "Components/OtherFooter";
import Styles from "./index.module.scss";

import { ReactComponent as MedalIcon } from "Assets/svg/medal.svg";
import AvatarImg from "Assets/images/Ellipse 18.png";
import ReactImg from "Assets/images/react.png";
import LaravelImg from "Assets/images/laravel.png";
import NodeImg from "Assets/images/nodejs.png";
import PythonImg from "Assets/images/python.png";
import avatarImg from "Assets/images/Ellipse 17.png";
import airpodsImg from "Assets/images/airpods.png";
import chairsImg from "Assets/images/chairs.png";
import CourseCard from "./atom/CourseCard";

const skillArray: ISkillItem[] = [
  { skillLogo: PythonImg, name: "Python" },
  { skillLogo: NodeImg, name: "Node Js" },
  { skillLogo: LaravelImg, name: "Laravel" },
  { skillLogo: ReactImg, name: "React Js" },
];

const projectInfo: IProjectCard = {
  heading: "VR and AR Combined in Visual System: The new visual pair",
  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.`,
  avatar: avatarImg,
  ownerName: "Johnny Billyman",
  ownerLocation: "Sweden",
};

const courseMockInfo: ICourseCard[] = [
  {
    title: `VR and AR Combined in Visual System: The new visual pair`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    date: "2 March, 2020",
    img: airpodsImg,
  },
  {
    title: `VR and AR Combined in Visual System: The new visual pair`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    date: "2 March, 2020",
    img: chairsImg,
  },
  {
    title: `VR and AR Combined in Visual System: The new visual pair`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    date: "2 March, 2020",
    img: airpodsImg,
  },
  {
    title: `VR and AR Combined in Visual System: The new visual pair`,
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
    date: "2 March, 2020",
    img: chairsImg,
  },
];

const Talent = () => {
  const [rating, updateRating] = useState(2.5);

  return (
    <div>
      <Navbar />
      <main className={Styles.mainContainer}>
        <div className={Styles.offCanvasCard}>
          <p className={Styles.canvasHeading}>
            You have 18 talents on your hiring list
          </p>
          <div className={Styles.canvasMainSection}>
            <div className={Styles.canvasCardImgContainer}>
              <div className={Styles.canvasCardImg}>
                <img src={AvatarImg} alt="user avatar" />
              </div>
              <div className={Styles.canvasCardImg}>
                <img src={AvatarImg} alt="user avatar" />
              </div>
              <div className={Styles.canvasCardImg}>
                <img src={AvatarImg} alt="user avatar" />
              </div>
              <div className={Styles.canvasCardImg}>
                <img src={AvatarImg} alt="user avatar" />
              </div>
              <div className={Styles.canvasCardImg}>
                <img src={AvatarImg} alt="user avatar" />
              </div>
            </div>
            <button>Complete Hiring</button>
          </div>
        </div>

        <section className={Styles.topSection}>
          <div className={Styles.topSection__left}>
            <div className={Styles.profileCard}>
              <div className={Styles.profileMedal}>
                <MedalIcon />
              </div>

              <div className={Styles.profileImage}>
                <img src={AvatarImg} alt="user avatar" />
              </div>
              <p className={Styles.profileName}>Jennifer Sandros</p>
              <p className={Styles.profileTitle}>Software Developer</p>
            </div>

            <p className={Styles.profileAbout}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi
              adipisci consectetur fuga quam id illo, esse harum ex ipsam
              voluptatem inventore voluptatibus quod pariatur ut voluptate
              repellendus hic facilis sint?
            </p>

            <div className={Styles.skillsCard}>
              <p className={Styles.skillsCard__title}>Verified Skills</p>
              <div className={Styles.skillsContainer}>
                {skillArray.map((skillI, index) => (
                  <SkillIcon {...skillI} key={index} />
                ))}
              </div>
            </div>
          </div>

          <div className={Styles.topSection__right}>
            <div className={Styles.detailsRow}>
              <p className={Styles.detailsRating}>Average rating: {rating}</p>
              <div>
                <StarRatings
                  rating={rating}
                  starRatedColor="#0251BC"
                  changeRating={updateRating}
                  numberOfStars={5}
                  starEmptyColor="#E2F6FF"
                  name="rating"
                  starHoverColor="#0251BC"
                  starDimension="23px"
                  starSpacing="5px"
                />
              </div>
            </div>
            <div className={Styles.detailsRow}>
              <div className={Styles.detailsColumn}>
                <p className={Styles.detailsLabel}>First Name</p>
                <p className={Styles.detailsLabelValue}>Pablo</p>
              </div>
              <div className={Styles.detailsColumn}>
                <p className={Styles.detailsLabel}>Last name</p>
                <p className={Styles.detailsLabelValue}>Last name</p>
              </div>
            </div>
            <div className={Styles.detailsRow}>
              <div className={Styles.detailsColumn}>
                <p className={Styles.detailsLabel}>Time zone</p>
                <p className={Styles.detailsLabelValue}>
                  West-central Africa (Africa, Lagos)
                </p>
              </div>
              <div className={Styles.detailsColumn}>
                <p className={Styles.detailsLabel}>Expected payout</p>
                <p className={Styles.detailsLabelValue}>$3,500</p>
              </div>
            </div>
            <div className={Styles.detailsRow}>
              <div className={Styles.detailsColumn}>
                <p className={Styles.detailsLabel}>Previous employer</p>
                <p className={Styles.detailsLabelValue}>Andela</p>
              </div>
              <div className={Styles.detailsColumn}>
                <p className={Styles.detailsLabel}>Current employer</p>
                <p className={Styles.detailsLabelValue}>Google</p>
              </div>
            </div>

            <div className={Styles.pageActions}>
              <button>Hire Now</button>
              <button>Schedule Interview</button>
            </div>
          </div>
        </section>
        <section className={Styles.bottomSection}>
          <div className={Styles.projectSection}>
            <h3 className={Styles.sectionTitle}>Projects - 20</h3>

            <div className={Styles.projectContainer}>
              <ProjectCard {...projectInfo} />
              <ProjectCard {...projectInfo} />
              <ProjectCard {...projectInfo} />
              <ProjectCard {...projectInfo} />
            </div>
          </div>
          <div className={Styles.courseSection}>
            <h3 className={Styles.sectionTitle}>Courses - 10</h3>
            <div className={Styles.courseContainer}>
              {courseMockInfo.map((courseI, index) => (
                <CourseCard {...courseI} key={index} />
              ))}
            </div>
          </div>
        </section>
      </main>
      <OtherFooter />
    </div>
  );
};

export default Talent;
